<template>
  <div class="help">{{ data.userInfo['employeeName'] }}，欢迎登录闲芯中台 1.0</div>
  <div class="copyright">©Copyright 2019-2022 深圳市芯链芯数据技术有限公司</div>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "desktop",
  emits : [ 'setMenuKey' ],
  setup(props, { emit }) {

    let data = reactive({
      userInfo : []
    });

    const getUserInfo = () => {

      proxy.postEIC('/human/employee/get.info', {})
          .then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              data.userInfo = res.data;
            }
          })

    }
    getUserInfo();

    return {
      data
    }

  }
}
</script>

<style scoped>
.help { text-align: center; position: relative; top: 40%; left: 50%; font-size: 18px;
  color: dodgerblue; border-bottom: 0.01em solid #cccccc; float: left; transform: translate(-50%, -50%); }
.copyright { position: absolute; bottom: 30px; text-align: center; width: 100%; font-size: 12px; color: #666666; }
</style>